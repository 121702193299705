<template>
  <section
    v-if="displayedPills.length > 0"
    class="page-drawer--section"
  >
    <div
      class="filter-tags"
    >
      <tag
        v-for="(pill, i) in displayedPills"
        :key="i"
        :label="pill.label"
        :close-icon="true"
        @click.native="
          pill.filterType === 'autocomplete' 
          ? removeAutocompleteFilter(pill.filterName) 
          : removeSelectFilter(pill.filterName, pill.value)
        "
      />
    </div>
    <div class="flex justify-centers items-center">
      <icon icon="actualiser" size="sm" />
      <span
        class="text-primary underline cursor-pointer"
        @click="removeFilters"
      >
        Réinitialiser
      </span>
    </div>
  </section>
</template>

<script>
import Tag from '@/components/commons/Tag';

export default {
  name: 'ActiveFilterPills',

  components: {
    Tag
  },

  props: {
    stateKey: {
      type: String,
      default: function () {
        return this.$route.name
      },
      required: false
    }
  },

  computed: {
    query() {
      return this.$route.query
    },

    selectFiltersPills () {
      const filters = this.$store.state[this.stateKey]?.filters ?? {}
      let pills = [];

      for (const [i, filter] of Object.entries(filters)) {
        if (!this.query.hasOwnProperty(i)) continue;

        const selectedItems = this.query[i].split(',')
        let items = []

        if (filter.items[0] && filter.items[0].subLabel) {
          for (const item of filter.items) {
            const subItems = item.subItems.filter((item) => {
              return selectedItems.some((selectedItem) => {
                return selectedItem === item.value
              })
            })

            if (subItems.length > 0) {
              items = [...items, ...subItems]
            }
          }
        } else {
          items = filter.items.filter((item) => {
            return selectedItems.some((selectedItem) => {
              return selectedItem.toString() === item.value.toString()
            })
          })
        }

        for (const item of items) {
          pills.push({ filterType: 'select', filterName: i, ...item })
        }
      }
      return pills
    },

    autocompleteFiltersPills () {
      if (!this.$store.state[this.stateKey]?.autocompletesValues) return [];

      const filters = this.$store.state[this.stateKey]?.autocompletesValues;
      let pills = [];

      for (const [i, filter] of Object.entries(filters)) {
        if (this.query.hasOwnProperty(i)) {
          pills.push({ filterType: 'autocomplete', filterName: i, ...filter })
        }
      }
      return pills
    },

    displayedPills () {
      return [...this.selectFiltersPills, ...this.autocompleteFiltersPills]
    }
  },

  methods: {
    async removeFilters () {
      // Prevent search keyword removed in search results
      if (this.$route.name === 'roadmap'){
        await this.$router.replace('roadmap')
      } else {
        const query = { motclef: this.$route.query.motclef }
        await this.$router.replace({ query })
      }

      await this.$store.dispatch('drawerStatus', false)

      this.$store.commit('RESET_AUTOCOMPLETES_VALUES')
    },

    removeSelectFilter (filter, value) {
      let query = { ...this.$route.query }
      const queryFilter = query[filter]
      const queryFilterValues = queryFilter.split(',')
      const filteredQueryFilterValues = queryFilterValues.filter((queryFilterValue) => {
        return queryFilterValue.toString() !== value.toString()
      })
      delete query[filter]

      if (filteredQueryFilterValues.length > 0) {
        query[filter] = filteredQueryFilterValues.join(',')
      }

      this.$router.push({ query })
    },

    async removeAutocompleteFilter (filterName) {
      await this.$store.commit('SET_AUTOCOMPLETE_VALUE', {
        filterName : filterName,
        label: '',
        value: ''
      })

      let query = { ...this.$route.query }
      delete query[filterName]
      await this.$router.replace({ query })
    }
  }
}
</script>

<style scoped lang="scss">
  .filter-tags {
    @apply p-2;

    .tag {
      @apply border-secondary text-secondary capitalize;
      max-width: 250px;
    }
  }

  .page-drawer--section {
    @apply flex flex-col justify-center items-center p-3;
  }
</style>
