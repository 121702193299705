const navbarItems = [
  {
    subItems: [
      {
        text: 'Mercurial',
        link: '/page/mercurial'
      }
    ]
  },
  {
    subItems: [
      {
        text: 'Liste des fournisseurs',
        link: '/fournisseurs',
        hiddenFor: [
          'supplier'
        ]
      },
      {
        text: 'Liste des adhérents',
        link: '/adherents'
      },
      {
        text: 'Formations',
        link: '/formations',
        hiddenFor: [
          'supplier'
        ]
      },
      {
        text: 'Conseil et délégation d\'achat',
        link: '/articles?filtre=conseil',
        hiddenFor: [
          'supplier'
        ]
      },
      {
        text: 'Fil d\'actualités',
        link: '/articles'
      }
    ]
  },
  {
    subItems: [
      {
        text: 'Tableau de bord',
        link: '/dashboard'
      },
      // {
      //   text: 'Feuille de route',
      //   link: '/feuille-de-route'
      // },
      {
        text: 'Indices',
        link: '/indices'
      },
      {
        text: 'Documents expert Achat',
        link: '/documents?category=47,127'
      }
    ]
  }
]

export default navbarItems
