<template>
  <li
    class="list_elaborated-item"
    @click="handleGoPage"
  >
      <img
      class="list_elaborated-item-img" alt=""
      :src="medias.source"
      >
    <div class="list_elaborated-item--content">
      <div class="list_elaborated-item--title">
        {{ title }}
      </div>

      <!-- <div class="list_elaborated-item--subtitle">
        <slot></slot>
      </div> -->
      <div class="text-xs text-primary" v-if="type === 'subscriber'">
      Adhérent
      </div>
      <div class="text-xs text-primary" v-if="type === 'supplier'">
      Fournisseur
      </div>
    </div>

    <div class="list_elaborated-item--icon">
      <icon
        icon="fleche-orange"
        size="md"></icon>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    slug: {
      type: String,
      default: '',
      required: false
    },
    medias: {
      type: Object,
      default: () => ({
        source: '',
      }),
      required: false
    }
  },

  methods: {
    handleGoPage () {
      if (this.type === 'webinar') {
        this.$router.push({ name: 'article', params: { slug: this.slug } })
      } else if (this.type === 'supplier' || this.type === 'subscriber') {
        this.$router.push({ name: `${this.type}Presentation`, params: { slug: this.slug } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list_elaborated-item-img {
    max-width: 108px;
    min-width: 108px;
    height: 50px;
    object-fit: contain;
    display: inline-flex;
    flex-direction: row;
    padding-top: 1rem;
}
</style>
