<template>
  <div class="indices-listing wrapper">
    <div class="container">
      <section class="container-header">
        <div class="container-header--title">
          <icon
            icon="indices-eco"
            size="xl" />
          <h1>LISTE DES INDICES</h1>
        </div>
        <div class="text-center">
          <button
            class="btn btn-dark btn-small mx-auto mt-3 sm:hidden"
            @click.stop="onFilterClick"
          >
            Filtrer
          </button>
        </div>
      </section>
    </div>

    <div class="background">
      <div class="subcontainer">
        <IndicesTableListing :indices="favorites" favorites />
        <IndicesTableListing :indices="indices" />
      </div>
    </div>
  </div>
</template>

<script>
import RenderHtml from '@/components/templates/RenderHtml.vue'
import IndicesTableListing from '@/components/customs/indices/TableListing.vue'
import { mapState } from 'vuex'
export default {
  components: {
    IndicesTableListing,
    RenderHtml,
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState('indices', {
      loading: state => state.loading,
      listing: state => state.listing,
    }),
    indices() {
      return this.listing.filter((indice) => {
        return !indice.favorite
      })
    },
    favorites() {
      return this.listing.filter((indice) => {
        return indice.favorite
      })
    },
  },

  watch: {
    '$route.query'() {
      this.fetch()
    }
  },

  beforeMount () {
    this.fetch()
  },

  methods: {
    fetch() {
      this.$store.dispatch('indices/getListing', this.$route.query)
    },
    onFilterClick() {
      this.$store.dispatch('drawerStatus', true)
    }
  }
}
</script>
