<template>
  <div
    class="select"
    v-click-outside="handleClose">
    <button
      class="btn btn-select"
      :class="{'disabled' : isEmpty}"
      @click="handleDropdown">
      <span :class="{'text-xs' : small}">
        {{ filter.label || 'Aucun filtre' }}
      </span>
      <icon
        class="transition-all duration-100 ease-in-out ml-3"
        :class="{'transform rotate-180' : isOpen}"
        icon="fleche-bas"
        size="xs"/>
    </button>

    <transition
      name="fade"
      mode="out-in">
      <div
        class="select-dropdown"
        v-if="isOpen">
        <ul class="select-list">
          <li
            v-for="(item, index) in filter.items"
            :class="{
              'select-list--item': !item.subLabel,
              'mt-3': item.subLabel && index === 0
            }"
            :key="index">

            <strong
              v-if="item.subLabel"
              class="pl-3 cursor-pointer"
              @click="handleClickSublabel(item)"
            >
              {{ item.subLabel }}
            </strong>
            <ul v-if="item.subItems">
              <li
                class="select-list--item"
                v-for="(subItem, subIndex) in item.subItems"
                :key="subIndex">
                <input
                  @change="handleFilter"
                  class="cursor-pointer"
                  type="checkbox"
                  :id="subItem.value"
                  :value="subItem.value"
                  v-model="checked">
                <label
                  :for="subItem.value"
                  class="cursor-pointer">
                  {{ subItem.label }}
                </label>
              </li>
            </ul>

            <div
              v-else
              class="select-list--item">
              <input
                @change="handleFilter"
                class="cursor-pointer"
                type="checkbox"
                :id="item.value"
                :value="item.value"
                v-model="checked">
              <label
                :for="item.value"
                class="cursor-pointer">
                {{ item.label }}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({
        value: '', // Définit quelle variable filtrer
        label: '', // Texte du bouton affiché à l'user
        items: [ // Liste des choix
          {
            value: '', // Valeur du choix pour la requête
            label: '' // Texte du choix affiché à l'user
          }
        ]
      }),
      required: true // Ne pas utiliser ce composant si filter est vide
    },

    small: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    isOpen: false,
    checked: []
  }),

  computed: {
    isEmpty () {
      return this.filter.items.length === 0
    }
  },

  methods: {
    handleClose () {
      this.isOpen = false
    },

    handleDropdown () {
      this.isOpen = !this.isOpen
    },

    /**
     * On récupère le type de filtre à appliquer ainsi que l'item sélectionné,
     * puis on redirige vers une nouvelle page en y accrochant les nouvelles
     * query params (ex: department=aisne).
     */
    handleFilter () {      
      const query = { ...this.$route.query }
      delete query.page
      if (this.checked.length > 0) {
        query[this.filter.value] = this.checked.join(',')
      } else if (query[this.filter.value]) {
        delete query[this.filter.value]
      }
      this.$router.replace({ query: query }).catch(() => {})
      this.$store.dispatch('drawerStatus', false)
    },

    // On regarde s'il n'y a pas des query déjà checkés dans la requête pour
    // garder le filtre en cours. Si oui => on check les checkboxes. S'il n'y a
    // aucune query correspondant, on s'assure de reset les filtres
    handleChecked () {
      if (this.$route.query[this.filter.value]) {
        this.checked = this.$route.query[this.filter.value].split(',')
      } else {
        this.checked = []
      }
    },

    handleClickSublabel (item) {
      this.checked = []
      for (const subItem of item.subItems) {
        this.checked.push(subItem.value)
      }
      this.handleFilter()
    }
  },

  watch: {
    /* 'checked' () {
      this.handleFilter()
    }, */

    $route (to, from) {
      // On ferme l'onglet quand on fait une redirection (au clique sur
      // n'importe quel item puisque le choix d'un item fait une redirection +
      // append d'un query param)
      this.isOpen = false
      this.handleChecked()
    }
  },

  beforeMount () {
    this.handleChecked()
  },

  directives: {
    ClickOutside
  }
}
</script>
