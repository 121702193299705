<template>
  <div class="tabs">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tabs-menu hide-on-print"
      :class="{ 'active' : index === active}"
      @click="handleTabs(index)">
      {{ tab.title }}
    </div>

    <div class="tabs-content hide-on-print">
      <template v-for="(tab, index) in tabs">
        <div
          :key="index"
          v-if="index === active">
          <div
            v-if="tab.hint"
            class="bg-tertiary text-white text-lg text-center p-4">
            {{ tab.hint }}
          </div>

          <div
            v-if="tab.title !== 'Sur mesure'"
            class="flex items-center justify-between p-4">
            <span class="text-sm font-bold">Durée :</span>
            <span>{{ metadatas.duration }}</span>
          </div>

          <div
            v-if="tab.title === 'Inter'"
            class="flex items-center justify-between p-4 border-t">
            <span class="text-sm font-bold">À partir de :</span>
            <span class="text-xl font-bold">{{ metadatas.price }}</span>
          </div>

          <div
            v-if="tab.title !== 'Sur mesure'"
            class="border-t py-4 px-10 flex flex-col items-center">
            <button
              v-if="tab.title === 'Inter'"
              class="btn btn-block btn-outline--tertiary"
              v-scroll-to="'#sessions'">Consulter les sessions (dates, lieux)</button>
            <button
              v-if="tab.title === 'Inter'"
              @click="handleSubscribe"
              class="btn btn-block btn-tertiary mt-4">Nous contacter</button>
            <router-link
              :to="{ name: 'form', query: { object: 'Demande de devis', event: title, receiverEmail: 'mercurial@mercurial.fr', receiverName: 'Équipe Mercurial' }}"
              class="text-black mt-4"
              :class="{ 'btn btn-tertiary btn-block' : active !== 0 }">
              Demander un devis
            </router-link>

            <div class="flex mt-8">
              <img
                src="@/assets/images/cpf.png"
                alt="Logo mon compte formation">
              <img
                class="ml-4"
                src="@/assets/images/certif.png"
                alt="Logo France compétences">
            </div>
          </div>

          <div class="p-4 border-t text-center" v-if="tab.title != 'Inter'">
            <p
              v-if="tab.title === 'Sur mesure'"
              class="mb-4">
              Cette thématique vous intéresse ? Nos experts conçoivent votre formation sur-mesure !
            </p>
            <router-link
              :to="{ name: 'form', query: { object: 'Question sur une formation', event: title, receiverEmail: 'mercurial@mercurial.fr', receiverName: 'Équipe Mercurial' }}"
              class="text-black mt-4">
              Nous contacter
            </router-link>
          </div>

          <div class="text-center p-2 m-2 uppercase font-bold bg-gray-200 text-sm">
            <span>Partager cette formation</span>
            <div class="flex items-center justify-center mt-2">
              <ShareNetwork
                network="linkedin"
                :url="shareUrl"
                title="Mercurial"
              >
                <icon
                  icon="icon-awesome-linkedin"
                  size="sm"
                  class="m-2"/>
              </ShareNetwork>

              <ShareNetwork
                network="email"
                :url="shareUrl"
                title="Mercurial"
              >
                <icon
                  icon="icon-awesome-mail"
                  size="sm"
                  class="m-2"/>
              </ShareNetwork>

              <icon
                icon="icon-awesome-link"
                size="sm"
                class="m-2 cursor-pointer"
                @click.native="handleCopy" />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="printableArea print-tabs">
      <div v-for="(tab, index) in tabs" :key="index">

        <div class="tabs-content"  v-if="tab.title === 'Inter'">

          <div
            class="flex items-center justify-between p-4">
            <span class="text-sm font-bold">Durée :</span>
            <span>{{ metadatas.duration }}</span>
          </div>

          <div
            class="flex items-center justify-between p-4 border-t">
            <span class="text-sm font-bold">À partir de :</span>
            <span class="text-xl font-bold">{{ metadatas.price }}</span>
          </div>


          </div>  <!-- END Tab menu -->
        </div>  <!-- END Tab Content -->
    </div>    <!-- END Printable Area -->

  </div>
</template>

<script>
export default {
  data: () => ({
    active: 0,
    tabs: [
      {
        title: 'Inter',
        hint: 'Formation dans vos centres'
      },
      {
        title: 'Intra',
        hint: 'Dans vos locaux ou à distance'
      },
      {
        title: 'Sur mesure',
        hint: 'Formation à la demande'
      }
    ]
  }),

  computed: {
    metadatas () {
      return this.$store.state.articles.current.metadatas
    },

    title () {
      return this.$store.state.articles.current.title
    },

    shareUrl () {
      return window.location.href
    },
    sessions () {
      return this.$store.state.articles.current.sessions
    },
  },

  methods: {
    handleTabs (index) {
      this.active = index
    },

    handleCopy () {

    },

    handleSubscribe () {
      this.$router.push(
        {
          name: 'form',
          query: {
            object: 'S\'inscrire à l\'évènement : '+ this.title,
            event: this.title,
            receiverEmail: 'mercurial@mercurial.fr',
            receiverName: 'Équipe Mercurial',
            isFormationContact: true
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  @apply flex flex-row flex-wrap items-center justify-between;
  @apply bg-white rounded-br-md rounded-bl-md shadow-xs;
  @apply sticky overflow-hidden;
  top: calc(var(--toolbar-size) + theme('spacing.12'));

  > :first-child {
    @apply border-l border-black;
  }

  &-menu {
    @apply cursor-pointer inline-block;
    @apply transition duration-150 ease-in-out;
    @apply p-4 border-t border-r border-b border-black;
    @apply uppercase font-bold text-lg text-center;
    flex: 1 auto;

    &.active {
      @apply bg-tertiary text-white border-tertiary;
    }
  }

  &-content {
    flex-basis: 100%;
    min-height: theme('spacing.4')
  }
}
</style>
