<template>
  <div
    v-if="isLoading"
    class="container">
    <h1 class="container-header mt-8">Chargement...</h1>
  </div>

  <div
    v-else
    class="wrapper">
    <div class="container">
      <div class="container-header">
        <div class="container-header--title">
          <a @click="$router.back()">Retour</a>
          <h1 class="title">{{ article.title }}</h1>

          <div class="metadatas">
            <div
              v-if="article.metadatas.date"
              class="metadatas-date">
              {{ article.metadatas.date }}
            </div>

            <div class="metadatas-infos">
              <span class="metadatas-type">
                {{ article.type }}
              </span>
              <span
                v-if="article.metadatas.time"
                class="metadatas-time">
                - {{ article.metadatas.time }}
              </span>
              <p
                v-if="article.metadatas.duration"
                class="metadatas-duration">
                Durée <strong class="text-xl">{{ article.metadatas.duration }}</strong>
              </p>
            </div>
          </div>

          <a
            v-if="article.type === 'Webinars' && article.metadatas.url.length > 0"
            class="btn btn-primary btn-large no-underline"
            target="_blank"
            :href="article.metadatas.url">
            <icon
              icon="acces-webinar"
              size="xl"
            />
            <span>Accéder au webinar</span>
          </a>

          <router-link
            v-if="article.type !== 'Webinars' && article.metadatas.subscription"
            :to="{name: 'form', query: {
              object: 'S\'inscrire à l\'évènement',
              event: article.title,
              eventDate: article.metadatas.date,
              receiverEmail: 'mercurial@mercurial.fr',
              receiverName: 'Équipe Mercurial'
            }}"
            class="btn btn-primary btn-large no-underline"
          >
            <span>S'inscrire à cet évènement</span>
          </router-link>
        </div>

        <div class="container-header--media">
          <img
            class="heading"
            :src="article.medias['heading'].source"
            :title="article.medias['heading'].title"
            :alt="article.medias['heading'].alt">
        </div>
      </div>
    </div>

    <div class="background">
      <div class="container">
        <div class="container-content">
          <div class="flex flex-wrap">
            <div class="lg:w-1/5 lg:order-2 w-full">
              <contact-card
                title="Contact Mercurial"
                :contact="article.contact"
                :event="article.title"
                :eventDate="article.metadatas.time"
              />
              <share />
            </div>

            <div class="lg:w-4/5 w-full lg:pr-10">
              <agenda
                v-if="article.metadatas.agenda"
                :text="article.metadatas.agenda"/>
              <div
                class="mt-8"
                v-html="article.body"></div>
            </div>
          </div>

          <div class="flex flex-col">
            <h2 class="uppercase font-bold text-xl">Dernières publications</h2>
            <list-articles :last="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Agenda from './blocks/Agenda.vue'
import Share from './blocks/Share.vue'
import ContactCard from '@/components/customs/contacts/ContactCard.vue'
import ListArticles from '@/components/customs/articles/ListArticles.vue'

export default {
  name: 'ArticleView',

  components: {
    Agenda,
    Share,
    ContactCard,
    ListArticles
  },

  computed: {
    isLoading () {
      return this.$store.state.articles.isProcessingArticle
    },

    article () {
      return this.$store.state.articles.current
    }
  },

  watch: {
    $route (to, from) {
      this.fetchArticle()
    }
  },

  methods: {
    fetchArticle () {
      this.$store.dispatch('requestArticleBySlug', this.$route.params.slug)
    }
  },

  beforeMount () {
    this.fetchArticle()
  }
}
</script>

<style lang="scss" scoped>
// Import de la mixin
@import '@/assets/styles/mixins.scss';

.container {
  &-header {
    @apply flex;

    &--title, &--media {
      @apply flex-1;
    }

    &--title {
      @apply flex flex-col justify-evenly items-start;
      @apply p-4;

      > * + * {
        @apply mt-3;
      }

      .title {
        @apply uppercase font-bold text-2xl;
      }

      .metadatas {
        @apply flex items-center justify-start;

        &-date {
          @apply border border-primary p-2 mr-4;
          @apply text-2xl font-bold text-primary text-center uppercase;
          width: 130px;
        }

        &-infos {
          @apply mt-4;
        }

        &-type, &-time {
          @apply text-xl uppercase font-bold;
        }

        &-duration {
          @apply text-lg;
        }
      }
    }

    &--media {
      @apply overflow-hidden relative;
      padding-top: 25%;

      .heading {
        @apply object-cover;
        @include absolutely-centered;
      }
    }
  }

  &-content {
    @apply py-10;
  }
}
</style>
