<template>
  <div class="indices-listing__table" :class="{ 'indices-listing__table--favorites': favorites }">
    <PrimeDatatable
      :value="indices"
      :rowClass="() => 'indice'"
      @row-click="onRowClick"
    >
      <PrimeColumn
        field="name"
        header="Nom de l’indice"
        :headerClass="!favorites ? 'hidden' : 'indice-name'"
        bodyClass="indice-name" />
      <PrimeColumn
        columnKey="valuesUpdateDate"
        :headerClass="!favorites ? 'hidden' : 'indice-update-date'"
        bodyClass="indice-update-date"
      >
        <template #header>
          <span class="p-column-title">
            Dernière<br/>mise à jour
          </span>
        </template>
        <template #body="slotProps">
          <span class="p-column-title">
            Dernière mise à jour
          </span>
          {{ slotProps.data.valuesUpdateDate }}
        </template>
      </PrimeColumn>
      <PrimeColumn
        columnKey="value"
        header="Valeur"
        :headerClass="!favorites ? 'hidden' : 'indice-value'"
        bodyClass="indice-value"
      >
        <template #body="slotProps">
          {{
            slotProps.data.value ? slotProps.data.value.replace('.00', '') + ' ' + slotProps.data.currency + (slotProps.data.currency && slotProps.data.unity ? '/' : '' ) + slotProps.data.unity : '—'
          }}
        </template>
      </PrimeColumn>
      <PrimeColumn
        columnKey="variation"
        :headerClass="!favorites ? 'hidden' : 'indice-variation'"
        bodyClass="indice-variation"
      >
        <template #header>
          <span class="p-column-title flex gap-x-2">
            Variation
            <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="variationTooltip" />
          </span>
        </template>
        <template #body="slotProps">
          <span class="p-column-title">Variation</span>
          <div
            v-if="slotProps.data.variation && slotProps.data.variation.value"
            :class="'indice-variation--' + slotProps.data.variation.direction"
          >
            <span class="value">
              {{ slotProps.data.variation.direction === 'top' ? '+' : (slotProps.data.variation.direction === 'bottom' ? '-' : '') }}{{ slotProps.data.variation.value.replace('.00', '') }}
            </span><br/>
            <span class="percentage">
              ({{ slotProps.data.variation.direction === 'top' ? '+' : (slotProps.data.variation.direction === 'bottom' ? '-' : '') }}{{ slotProps.data.variation.percentage.replace('.00', '') }}%)
            </span>
          </div>
          <div v-else>
            —
          </div>
        </template>
      </PrimeColumn>
      <PrimeColumn
        columnKey="januaryVariation"
        :headerClass="!favorites ? 'hidden' : 'indice-variation'"
        bodyClass="indice-variation"
      >
        <template #header>
          <span class="p-column-title flex gap-x-2">
            Variation<br/>janvier
            <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="januaryVariationTooltip" />
          </span>
        </template>
        <template #body="slotProps">
          <span class="p-column-title">Variation janvier</span>
          <div
            v-if="slotProps.data.januaryVariation && slotProps.data.januaryVariation.value"
            :class="'indice-variation--' + slotProps.data.januaryVariation.direction"
          >
            <span class="value">
              {{ slotProps.data.januaryVariation.direction === 'top' ? '+' : (slotProps.data.januaryVariation.direction === 'bottom' ? '-' : '') }}{{ slotProps.data.januaryVariation.value.replace('.00', '') }}
            </span><br/>
            <span class="percentage">
              ({{
                slotProps.data.januaryVariation.direction === 'top' ? '+' : (slotProps.data.januaryVariation.direction === 'bottom' ? '-' : '')
              }}{{ slotProps.data.januaryVariation.percentage.replace('.00', '') }}%)
            </span>
          </div>
          <div v-else>
            —
          </div>
        </template>
      </PrimeColumn>
      <PrimeColumn
        columnKey="yearVariation"
        :headerClass="!favorites ? 'hidden' : 'indice-variation'"
        bodyClass="indice-variation"
      >
        <template #header>
          <span class="p-column-title flex gap-x-2">
            Variation<br/>1an
            <icon icon="infos-primary-bluer" size="sm" v-tooltip.top="yearVariationTooltip" />
          </span>
        </template>
        <template #body="slotProps">
          <span class="p-column-title">Variation 1an</span>
          <div
            v-if="slotProps.data.yearVariation && slotProps.data.yearVariation.value"
            :class="'indice-variation--' + slotProps.data.yearVariation.direction"
          >
            <span class="value">
              {{ slotProps.data.yearVariation.direction === 'top' ? '+' : (slotProps.data.yearVariation.direction === 'bottom' ? '-' : '') }}{{ slotProps.data.yearVariation.value.replace('.00', '') }}
            </span><br/>
            <span class="percentage">
              ({{
                slotProps.data.yearVariation.direction === 'top' ? '+' : (slotProps.data.yearVariation.direction === 'bottom' ? '-' : '')
              }}{{ slotProps.data.yearVariation.percentage.replace('.00', '') }}%)
            </span>
          </div>
          <div v-else>
            —
          </div>
        </template>
      </PrimeColumn>
      <PrimeColumn
        columnKey="favorite"
        :headerClass="!favorites ? 'hidden' : 'indice-favorite'"
        body-class="indice-favorite"
      >
        <template #body="slotProps">
          <div
            class="flex justify-center"
            @click.stop="onFavoriteClick(slotProps.data.id)"
          >
            <icon
              :icon="favorites ? 'pin-on' : 'pin-off'"
              size="lg" />
          </div>
        </template>
      </PrimeColumn>
      <template #empty>
        {{ favorites ? 'Aucun indice épinglé' : 'Aucun indice' }}
      </template>
    </PrimeDatatable>

    <modal class="indices-listing__dialog--img" :visible.sync="modalImage.display">
      <img
        :src="modalImage.src"
        :alt="modalImage.alt"
      />
    </modal>
  </div>
</template>

<script>

export default {
  props: {
    indices: {
      type: Array,
      default: []
    },
    favorites: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      variationTooltip: 'Compare la dernière valeur à la valeur précédente et affiche la variation en pourcentage et/ou en unité d’achat',
      januaryVariationTooltip: 'Compare la dernière valeur à la valeur au 1er Janvier de l’année en cours et affiche la variation en pourcentage et/ou en unité d’achat',
      yearVariationTooltip: 'Compare la dernière valeur à la valeur à la même date de l’année précédente et affiche la variation en pourcentage et/ou en unité d’achat',
      modalImage: {
        display: false,
        src: '',
        alt: '',
      }
    }
  },

  methods: {
    onFavoriteClick(id) {
      this.$store.dispatch('indices/toggleFavorite', id)
    },
    onRowClick(e) {
      const indice = e.data || {}
      if (indice.type === 'excel_download') {
        window.open(indice.excel?.src)
      } else if (indice.type === 'image') {
        this.modalImage.src = indice.image?.src
        this.modalImage.alt = indice.image?.alt
        this.modalImage.display = true
      } else {
        this.$router.push({ name: 'indice-detail', params: { id: indice.id }})
      }
    }
  }
}
</script>
