<template>
    <section>
        <nav
            :class="['flex', 'justify-center', 'items-center', 'md:justify-between', 'flex-col', 'md:flex-row', 'my-4', 'md:my-12']"
        >
            <button
                class="lg:hidden btn btn-dark btn-small mb-8 md:mb-0"
                :style="{ width: '117px', height: '30px'}"
                @click="handleOpenDrawer"
            >
                Filtrer
            </button>
            <button 
                v-if="subscriberInteraction"
                @click="downloadFile(subscriberInteraction.slug)"
                :class="['btn', 'btn-small', 'btn-outline--primary', 'mb-8', 'md:mb-0']"
            >
               {{ subscriberInteraction.btnLabel }}
            </button>
            <button 
                @click="exportXLSXInteractions()"
                :class="['btn', 'btn-small', 'btn-outline--primary', 'mb-8', 'md:mb-0']"
            >
                Export de la liste au format xlsx
            </button>
        </nav>

        <ul class="accordion hide-on-print">
            <li v-for="item in this.interactions"
                :key="item.id"
                :class="[
                    'accordion-item',
                    'accordion-item--interaction',
                    active.includes(item.id) ? 'active' : null
                ]"
            >
                <Card leftTitle dense>
                    <template slot="header">
                        <div :class="[isFiliales ? 'md:w-5/12' : 'md:w-6/12', 'w-full', 'mb-2', 'md:mb-0']">
                            <h2 class="uppercase">{{ item.purchase_family ? item.purchase_family : '' }}</h2>
                            <div>{{ item.purchase_category }}</div>
                        </div>
                        <div :class="['hidden', 'md:flex', 'md:w-4/12', 'w-full', 'items-center', 'justify-end', 'mr-5']">
                            <div :class="['status', 'hidden', 'md:flex']">
                                {{ item.status ? item.status : '(Non défini)' }}
                                <span :class="'status_circle'" :style="{ backgroundColor: colors(item.status)}"></span>
                            </div>
                        </div>
                        <div :class="[isFiliales ? 'md:w-3/12' : 'md:w-2/12', 'w-full', 'flex', 'flex-col', 'md:flex-row', 'flex-wrap', 'items-start', 'md:items-center', 'justify-between', 'md:justify-end']">
                            <div :class="['status', 'flex', 'md:hidden']">
                                {{ item.status ? item.status : '(Non défini)' }}
                                <span :class="'status_circle'" :style="{ backgroundColor: colors(item.status)}"></span>
                            </div>
                            <div :class="['flex', 'items-center', isFiliales ? 'justify-between' : 'justify-end', 'w-full']">
                                <div v-show="isFiliales" :style="{ lineHeight: '1.1', textAlign: 'end', marginRight: '5px' }">
                                {{ item.affiliateName ? item.affiliateName : '' }}
                                </div>
                                <button
                                    :class="['py-4', 'px-4', 'md:px-6']"
                                    @click="handleClick(item.id)"
                                >
                                    <icon
                                        :icon="'fleche-bas-white'"
                                        :color="'white'"
                                        :style="active.includes(item.id) ? { transform: 'rotate(180deg)' } : null"
                                        size="md">  
                                    </icon>
                                </button>
                            </div>
                        </div>
                    </template>

                    <template v-if=" active.includes(item.id)">
                        <div class="accordion-item--content">
                            <!-- FirstLine -->
                            <div :class="['accordion-item--interaction__section']">
                                <div :class="['item__line', 'item__line--25', !item.requestDate ? 'hidden md:flex': 'flex']">
                                    <div v-if="item.requestDate" :class="'item__stamp'">
                                        Étude initiée le&nbsp;:&nbsp;<strong :class="'ml-2'">{{ item.requestDate }}</strong>
                                    </div>
                                </div>

                                <div :class="['item__line', 'flex', 'item__line--50', !item.actors && !item.actors.length ? 'hidden md:flex': 'flex']">
                                    <template v-if="item.actors && item.actors.length">
                                        <div v-for="actor in item.actors" :class="['flex', 'flex-col', 'md:flex-row', 'justify-between', 'items-center', 'w-full']">
                                            <div :class="['flex', 'flex-col', 'md:flex-row', 'items-center']">
                                                <span>Référent Mercurial&nbsp;:&nbsp;</span>
                                                <strong >{{ actor.name }}</strong>
                                            </div>
                                            <button
                                                @click.stop="contactHandler(
                                                    actor.email,
                                                    actor.name,
                                                    'Intéraction initiée le ' + item.requestDate,
                                                    item.purchase_family,
                                                )"
                                                :class="['btn', 'btn-small', 'btn-outline--primary']"
                                            >
                                                Contacter
                                            </button>
                                        </div>
                                    </template>
                                </div>

                                <div 
                                    :class="['item__line', 'item__line--25', 'justify-center', !item.correspondents || item.correspondents.length < 1 ? 'hidden md:flex': 'flex']" 
                                    :style="!item.correspondents || item.correspondents.length < 1 ? { borderColor: 'transparent'} : null"
                                >
                                    <div v-if="item.correspondents && item.correspondents.length" :class="['flex', 'flex-wrap', 'justify-start']">
                                        Contact&nbsp;:&nbsp;
                                        <template v-for="correspondent in item.correspondents">
                                            <strong :class="'ml-2'">{{ correspondent.name }}</strong>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <!-- SecondLine -->
                            <div v-if="item.mercurial_comment" :class="['accordion-item--interaction__section']">
                                <div class="item__line w-full item__line--comments">
                                    <strong>Commentaires&nbsp;:&nbsp;</strong> {{ item.mercurial_comment }}
                                </div>
                            </div>
                            <!-- Final Line -->
                            <div :class="['accordion-item--interaction__section--final']">
                                <div :class="['item__line', 'item__line--25', !item.currentSuppliers || item.currentSuppliers.length < 1 ? 'hidden md:flex': 'flex']">
                                    <div v-if="item.currentSuppliers && item.currentSuppliers.length">
                                        Fournisseur(s) actuel(s)<br />
                                        <template v-for="currentSupplier in item.currentSuppliers">
                                            <strong>{{ currentSupplier.name }}</strong>
                                        </template>
                                    </div>
                                </div>
                                <div :class="['item__line', 'item__line--25', !item.recommendedSuppliers || item.recommendedSuppliers.length < 1 ? 'hidden md:flex': 'flex']">
                                    <div v-if="item.recommendedSuppliers && item.recommendedSuppliers.length">
                                        Fournisseur préconisé<br />
                                        <template v-for="recommendedSupplier in item.recommendedSuppliers">
                                            <strong>{{ recommendedSupplier.name }}</strong>
                                        </template>
                                    </div>
                                </div>
                                <div :class="['item__line', 'item__line--25', !item.selectedSuppliers || item.selectedSuppliers.length < 1 ? 'hidden md:flex': 'flex']">
                                    <div v-if="item.selectedSuppliers && item.selectedSuppliers.length">
                                        Fournisseur(s) séléctionné(s)<br />
                                        <template v-for="selectedSupplier in item.selectedSuppliers">
                                            <strong>{{ selectedSupplier.name }}</strong>
                                        </template>
                                    </div>
                                </div>
                                <div :class="['item__line', 'item__line--results', 'item__line--25', !item.attachments || item.attachments.length < 1 ? 'hidden md:flex': 'flex']">
                                    <div :style="{ justifyContent: 'start'}">
                                        <template v-if="item.attachments && item.attachments.length > 0">
                                            <button 
                                                v-for="attachment in item.attachments" 
                                                :key="attachment.slug"
                                                :style="{ padding: '0'}"
                                                @click="downloadFile(attachment.slug)"
                                            >
                                                <span
                                                    :class="['text-primary', 'underline', 'flex']"
                                                >
                                                    <img
                                                        src="@/assets/images/icons/attachment.svg"
                                                        alt="pièce_jointe"
                                                        width="15"
                                                        class="mr-1"
                                                    />
                                                    Document attaché <span v-if="item.attachments.length > 1">{{ attachment.slug }},</span>
                                                </span>
                                            </button>
                                        </template>
                                        <div v-else :class="'flex'">
                                            <img
                                                src="@/assets/images/icons/attachment.svg"
                                                alt="pièce_jointe"
                                                width="15"
                                                class="mr-1"
                                            /> Pas de document
                                        </div>
                                    </div>
                                    <div :class="['flex']" :style="{ justifyContent: 'space-between'}">
                                        <div>
                                            Résultat&nbsp;:&nbsp;
                                            <strong v-if="item.result">
                                                {{ item.result }}%
                                            </strong>
                                            <strong v-else>
                                                -
                                            </strong>
                                        </div>
                                        <icon 
                                            icon="infos-primary-bluer" 
                                            size="md" 
                                            v-tooltip.top="'Gain moyen estimé sur base des éléments mis à la disposition de Mercurial. Ce résultat n\'engage pas Mercurial et correspond seulement à une analyse à un instant donné et à des éléments passés ou présents.'"
                                        />
                                    </div>
                                    <div :class="['flex']" :style="{ justifyContent: 'space-between'}">
                                        <div>
                                            Montant d'achat étudié&nbsp;:&nbsp;
                                            <strong v-if="item.potential_revenue">
                                                {{ item.potential_revenue }}
                                            </strong>
                                            <strong v-else>
                                                -
                                            </strong>
                                        </div>
                                        <icon 
                                            icon="infos-primary-bluer"
                                            size="md" 
                                            v-tooltip.top="'Montant basé sur les éléments mis à la disposition de Mercurial \r\n( factures, balances...)'"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
            </li>
        </ul>

        <pagination :page-count="pageCount" />
    </section>
</template>
  
<script>
import $axios from "@/axios";
import Card from '@/components/commons/Card.vue'
import Pagination from '@/components/commons/Pagination'

export default {
    components: {
        Card,
        Pagination
    },

    data: () => ({
        active: [],
        interactions: [],
        subscriberInteraction: null,
        pager: null,
        excelContent: [],
    }),

    async created(){
        await this.fetchInteractions();

        await this.fetchAffiliates();
    },

    computed: {
        isFiliales () {
            return this.$store.state.subscribers.isAffiliates
        },
        colors() {
            return (status) => {
                const colors = [
                    {
                        status: 'Étude en cours',
                        color: '#F5CB19',
                    },
                    {
                        status: 'Étude abandonnée',
                        color: 'black',
                    },
                    {
                        status: 'Étude réalisée',
                        color: '#015198',
                    },
                    {
                        status: 'Fournisseur Mercurial sélectionné',
                        color: '#92C03A',
                    },
                    {
                        status: 'Étude non exploitable',
                        color: 'black',
                    },
                    {
                        status: 'Étude non sélectionnée',
                        color: '#E73128',
                    },
                ];

                return colors.find(color => color.status === status)?.color ? colors.find(color => color.status === status)?.color : 'grey'
            }
        },
        pageCount() {
            return this.pager && this.pager.pageCount ? this.pager.pageCount : 1
        }
    },

    watch: {
        '$route.query': {
            handler: function() {
                this.fetchInteractions();
            },
            deep: true,
            immediate: true
        },
    },

    methods: {
        /**
         * Handle Open
         * @return {void}
         */
        handleOpenDrawer () {
            this.$store.commit('SET_DRAWER',true)
        },
        contactHandler(contactEmail, contactName, object, family){
            this.$router.push(
                {
                    name: 'form',
                    query: {
                        object: object,
                        family: family,
                        receiverEmail: contactEmail,
                        receiverName: contactName,
                        event: this.event,
                    }
                }
            )
        },
        /**
         * Handle click action
         * @param {any} index
         * @return {void}
         */
        handleClick(index) {
            this.active.includes(index)
                ? this.active.splice(this.active.indexOf(index), 1)
                : this.active.push(index)
        },
        /**
         * Query Request Builder
         * @param {string|number|null} args
         * @param {boolean} isXlsx
         * @return {string}
         */
        buildQueryRequest(args, isXlsx = false){
            let queryRequest = isXlsx ? '?xlsx=true' : '';

            Object.entries(args).forEach(([key, value], index) => {
                const values = value?.split(',')

                values.forEach((value, jndex) => {
                    queryRequest += (index === 0 && jndex === 0 && !isXlsx ? '?' : '&') + key + (key === 'page' ? '=' : '[' + jndex + ']=') + value
                });
            });

            return queryRequest;
        },
        /**
         * Donwload File Helper
         * @param {any} slug
         * @return {void}
         */
        async downloadFile(slug){
            try {
                const blob = await $axios.get(`${process.env.VUE_APP_API_VERSION}/documents/${slug}/file`, {
                    responseType: 'blob'
                })

                const filename = blob.headers['content-disposition'].split('filename=')[1].replaceAll('"', '')
                const url = window.URL.createObjectURL(new Blob([blob.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            } catch (error) {
                console.log(error)
            }
        },
        /**
         * Fetch Interactions List
         * @return {Promise<void>}
         */
        async fetchInteractions(){
            const response = await $axios.get(
                `${process.env.VUE_APP_API_VERSION}/interactions` + this.buildQueryRequest(this.$route.query)
            )

            const filtersMapped = (filters) => {
                const result = {};

                for (const key in filters) {
                    result[key] = {
                        label: filters[key].label,
                        items: Object.entries(filters[key].items).map(([itemKey, itemValue]) => ({
                            label: itemValue.label,
                            value: isNaN(itemKey) ? itemKey : parseInt(itemKey)
                        }))
                    };
                }

                return result;
            }
            
            this.subscriberInteraction = response.data.subscriberInteraction;
            this.interactions = response.data.interactions;
            this.$store.commit('SET_ROADMAP_FILTERS', filtersMapped(response.data.filters))
            this.pager = response.data.pager;
            this.active = [];
            this.active.push(response.data.interactions[0]?.id)
        },
        /**     
         * TODO: Actuellement ,je n'ai pas trouvé de meilleure solution que d'intéroger la route prévue pour le dashboard pour voir si il y a des filiales :-/
         */
        async fetchAffiliates () {
            const response = await $axios.get(`${process.env.VUE_APP_API_VERSION}/dashboard/affiliates`)

            this.affiliates = response.data.affiliates;

            this.$store.commit('SET_IS_AFFILIATES', this.affiliates.length > 1)
        },
        /**
         * Fetch Interactions List and export XLSX
         * @return {Promise<void>}
         */
        async exportXLSXInteractions(){
            try {
                const content = await $axios.get(
                    `${process.env.VUE_APP_API_VERSION}/interactions` + this.buildQueryRequest(this.$route.query, true)
                )

                this.excelContent = content.data.rows

                const sheet = this.$xlsx.utils.json_to_sheet(this.excelContent)
                const wb = this.$xlsx.utils.book_new()

                this.$xlsx.utils.book_append_sheet(wb, sheet, 'Feuille 1')
                // export Excel file
                this.$xlsx.writeFile(wb, 'fournisseurs.xlsx')
            } catch (err){
                console.warn(err)
            }
        }
    },
}
</script>

<style lang="scss">
.accordion-item--interaction {
    @apply mb-8;
    color: #1B2E3C;

    .card-header {
        @apply bg-primary text-white;
    }

    .status {

        text-align: end;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 767px){
            flex-direction: row-reverse;
        }
    }

    .status_circle {
        @apply ml-4;
        @apply mr-0;
        width: 20px;
        height: 20px;
        border: 2px solid #FFFFFF;
        display: block;
        border-radius: 50%;
        opacity: 1;

        @media screen and (max-width: 767px){
            @apply ml-0;
            @apply mr-4;
        }
    }

    button {
        &:not(.btn.btn-small.btn-outline--primary){
            border: none;
            background: none;
        }
    }

    &__section {
        
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &--final {
            border-bottom: 1px solid #e0e0e0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: none;

            @media screen and (max-width: 767px){
                flex-direction: column;
            }

            .item__line--25 {
                @media screen and (max-width: 767px){
                    border-bottom: 1px solid #e0e0e0;
                }
            }
        }
    }
    @media screen and (max-width: 767px){
        &__section {
            flex-direction: column;
        }
    }

    .item__line {
        padding: 1rem;
        flex-direction: row;

        &--comments {
            @media screen and (max-width: 767px){
                flex-direction: column;
            }
            strong {
                margin-right: 1em;
                hyphens: none;
            }
        }

        &--results {
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 0;

            > div {
                justify-content: flex-start;
                width: 100%;
                padding: 10px;
                border-bottom: 1px solid #e0e0e0;

                &:last-child {
                    border-bottom: none; 
                }
            }
        }

        &:nth-child(n+2):last-child {
            border-left: 1px solid #e0e0e0;
            @media screen and (max-width: 767px){
                border-left: unset;
            }

            > div {
                height: auto;
                min-height: 38px;
                line-height: 1.1;
                width: 100%;
                display: flex;
                align-items: center;
            }
        }

        &--25 {
            width: 25%;

            @media screen and (max-width: 767px){
                width: 100%;
                > * {
                    width: 100%;
                    text-align: center;
                }

                // border-bottom: 1px solid #e0e0e0;
            }
        }

        &--50 {
            width: 50%;
            @media screen and (max-width: 767px){
                border-bottom: 1px solid #e0e0e0;
                width: 100%;
            }
        }
    }

    .item__stamp {
        width: 100%;
        height: 38px;
        background: #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 768px){
            max-width: 230px;
        }
    }
}
</style>
