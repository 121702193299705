<template>
  <div
    class="market"
    :class="[{ 'market_card' : card }]"
    v-if="indices">
    <div class="market-item">
      <div
        class="market-item--stats"
        v-if="indices.cac">
        <h4 class="market-item--title">{{indices.cac.name}}</h4>
        <span class="market-item--value">{{indices.cac.valeur}}</span>
        <span class="market-item--variation">{{indices.cac.variation}}</span>
      </div>
      <div
        class="chart"
        ref="chartcac">
      </div>
    </div>
    <div class="market-item">
      <div
        class="market-item--stats"
        v-if="indices.dow">
        <h4 class="market-item--title">{{indices.dow.name}}</h4>
        <span class="market-item--value">{{indices.dow.valeur}}</span>
        <span class="market-item--variation">{{indices.dow.variation}}</span>
      </div>
      <div
        class="chart"
        ref="chartdow">
      </div>
    </div>
    <div class="market-item">
      <div
        class="market-item--stats"
        v-if="indices.dollar">
        <h4 class="market-item--title">{{indices.dollar.name}}</h4>
        <span class="market-item--value">{{indices.dollar.valeur}}</span>
        <span class="market-item--variation">{{indices.dollar.variation}}</span>
      </div>
      <div
        class="chart"
        ref="chartdollar">
      </div>
    </div>
    <div class="market-item">
      <div
        class="market-item--stats"
        v-if="indices.brent">
        <h4 class="market-item--title">{{indices.brent.name}}</h4>
        <span class="market-item--value">{{indices.brent.valeur}}</span>
        <span class="market-item--variation">{{indices.brent.variation}}</span>
      </div>
      <div
        class="chart"
        ref="chartbrent">
      </div>
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

/* eslint-disable */
// On va respecter la doc de la lib et ne pas suivre notre linter :
// https://www.amcharts.com/docs/v4/getting-started/integrations/using-vue-js/
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
// import am4themes_microchart from "@amcharts/amcharts4/themes/microchart";

am4core.useTheme(am4themes_animated)
// am4core.useTheme(am4themes_microchart);

// https://stackoverflow.com/questions/57572182/making-am4charts-reactive-in-vuejs

export default {
  props: {
    card: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    indices () {
      return this.$store.state.home.macroeconomic
    }
  },

  methods: {
    fetchMacroeconomicDatas () {
      this.$store.dispatch('home/requestMacroeconomic')
    },

    displayChart (type) {
      const chart = am4core.create(this.$refs['chart' + type], am4charts.XYChart)
      chart.colors.list = [
        am4core.color('#0061a6'),
        am4core.color('#1b2e3c')
      ]

      const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'period'
      xAxis.renderer.grid.template.disabled = true
      xAxis.renderer.baseGrid.disabled = true
      xAxis.renderer.labels.template.disabled = true

      const yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.renderer.grid.template.disabled = true
      yAxis.renderer.labels.template.disabled = true

      const indiceDatas = JSON.parse(JSON.stringify(this.indices[type].chart.data.amChartData))

      for (const data in indiceDatas) {
        chart.data = indiceDatas[data]
      }

      const series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.categoryX = 'period'
      series.name = ''

      chart.logo.disabled = true

      this.chart = chart
    }
  },

  watch: {
    '$store.state.home.macroeconomic': function () {
      this.displayChart('cac')
      this.displayChart('dow')
      this.displayChart('dollar')
      this.displayChart('brent')
    }
  },

  // mounted () {
  //   this.displayChart()
  // },

  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },

  beforeMount () {
    // On requête d'abord les filtres possibles
    this.fetchMacroeconomicDatas()
  }
}
</script>

<style lang="scss" scoped>
.market {
  @apply flex flex-col items-center justify-items-stretch flex-wrap h-full;

  @screen lg {
    @apply flex-row;
  }

  &-item {
    @apply flex items-center justify-evenly;

    @screen lg {
      @apply w-1/2;
    }

    &--title {
      @apply block;
    }

    &--value {
      @apply block font-bold;
      color: #0061A6;
    }

    &--variation {
      @apply block text-secondary text-sm;
    }

    &--chart {
      /*@apply mx-4 bg-gray-300;*/
      width: 100px;
      height: 60px
    }
  }

  &_card {
    @apply grid grid-cols-1 gap-4;
    .market-item {
      @apply w-full py-4;
      @apply rounded shadow-xs;
    }
    @screen md {
      @apply grid-cols-2;
    }
    @screen lg {
      @apply grid-cols-4;
    }
  }
}

.chart {
  width: 200px;
  height: 60px;
}
</style>
