<template>
  <div class="chart" ref="chart" />
</template>

<script>
/* Imports */
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR'
import dates from '@/mixins/dates'

export default {
  mixins: [dates],

  props: {
    points: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: '€'
    },
    unity: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      chartData: null
    }
  },

  computed: {
    filters() {
      return this.$store.state.indices.detail.filters
    },
    yAxisTitle() {
       return this.currency + (this.currency && this.unity ? '/' : '') + this.unity
    },
    firstExcelPoint() {
      return this.points.findIndex(point => {
        return this.parseDateFromFormat(point.date, 'dd/mm/yyyy') >= this.filters.startDate
      })
    },
    lastExcelPoint() {
      return this.points.findIndex(point => {
        return this.parseDateFromFormat(point.date, 'dd/mm/yyyy') >= this.filters.endDate
      })
    },
  },

  watch: {
    points(newVal) {
      if (!this.chartData) {
        this.chartData = newVal
      }
    },
    filters: {
      handler(newVal, oldVal) {
        if (oldVal.currency && newVal.currency !== oldVal.currency) {
          this.chartData = null
        } else if (newVal.startDate !== oldVal.startDate || newVal.endDate !== oldVal.endDate) {
          this.setRange(newVal.startDate, newVal.endDate)
        }
      },
      deep: true
    },
    chartData(newVal) {
      if (this.chart) {
        this.chart.data = newVal
        this.chart.exporting.adapter.add("data", (data) => {
          data.data = data.data.slice(this.firstExcelPoint, this.lastExcelPoint)
          return data
        });
      }
    },
    yAxisTitle(newVal) {
      if (this.chart) {
        this.chart._yAxes._values[0].title.text = newVal
      }
    }
  },

  mounted() {
    // Create chart instance
    let chart = am4core.create(this.$refs.chart, am4charts.XYChart);
    chart.language.locale = am4lang_fr_FR;

    chart.dateFormatter.inputDateFormat = 'dd/MM/yyyy';

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormats.setKey("day", "dd/MM/yyyy");
    dateAxis.periodChangeDateFormats.setKey("month", "[bold]yyyy[/]");
    dateAxis.renderer.tooltipLocation = 0;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.strokeWidth = 1;
    series.fillOpacity = 0.1;
    series.tooltipText = "{valueY}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.background.fillOpacity = 0.5;
    series.dataItems.template.locations.dateX = 0;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    var scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginTop = 45;
    scrollbarX.marginBottom = 20;
    chart.scrollbarX = scrollbarX;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = 'left';
    chart.exporting.menu.items = [{
      label: 'exporter',
      defaultStyles: false,
      "menu": [
        { "type": "pdf", "label": "PDF" },
        { "type": "print", "label": "Print",  },
        { "type": "xlsx", "label": "Excel" }
      ]
    }];

    const endDate = this.parseDateFromFormat(this.endDate, 'dd/mm/yyyy')
    chart.events.on("datavalidated", function () {
      dateAxis.zoomToDates(
        new Date(new Date().setFullYear(endDate.getFullYear() - 2, endDate.getMonth(), endDate.getDate())),
        endDate,
        false,
        true
      );
    });

    chart.logo.disabled = true
    this.chart = chart
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

  methods: {
    setRange(startDate, endDate) {
      if (this.chart && this.chart.data) {
        if (!startDate) {
          startDate = this.parseDateFromFormat(this.chart.data[0]?.date, 'dd/mm/yyyy')
        }
        if (!endDate) {
          endDate = this.parseDateFromFormat(this.chart.data[this.chart.data.length - 1]?.date, 'dd/mm/yyyy')
        }
        this.chart.xAxes._values[0].zoomToDates(
          startDate,
          endDate,
          false,
          true
        )
      }
    }
  }
}
</script>
