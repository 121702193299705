<template>
  <div id="app">
    <mercurial-header />
    <mercurial-navbar />
    <main
      role="main"
      class="page">
      <transition
        name="slide_left"
        mode="out-in"
        appear>
        <mercurial-drawer v-if="$route.meta.hasDrawer" />
      </transition>
      <div class="page-content">
        <transition
          name="fade"
          mode="out-in">
          <router-view />
        </transition>
      </div>
      <mercurial-sidebar />
    </main>
    <mercurial-footer />
  </div>
</template>

<script>
import MercurialHeader from '@/layout/blocks/MercurialHeader.vue'
import MercurialNavbar from '@/layout/blocks/MercurialNavbar.vue'
import MercurialSidebar from '@/layout/blocks/MercurialSidebar.vue'
import MercurialDrawer from '@/layout/blocks/MercurialDrawer.vue'
import MercurialFooter from '@/layout/blocks/MercurialFooter.vue'

export default {
  components: {
    MercurialHeader,
    MercurialNavbar,
    MercurialSidebar,
    MercurialDrawer,
    MercurialFooter
  },

  mounted() {
    this.track()
  },

  methods: {
    track () {
      this.$gtm.debugEnabled();

      if (this.$gtm.enabled()) {
        var excludedIds = ['22', '23', '7488'];
        if (excludedIds.indexOf(this.$store.state.authentication.user.id) == -1) {
        window.dataLayer?.push({
          user_role: this.$store.state.authentication.user.roles[0],
          user_activity_branch: this.$store.state.authentication.user.activityBranch,
          user_profile: this.$store.state.authentication.user.profile,
          user_name: this.$store.state.authentication.user.referentMercurialName,
          contact_service: this.$store.state.authentication.user.service,
          contact_fonction: this.$store.state.authentication.user.function,
          contact_regions: this.$store.state.authentication.user.regions,
          enterprise_edeal_id: this.$store.state.authentication.user.enterprise.idEdeal,
          enterprise_name: this.$store.state.authentication.user.enterprise.title,
          enterprise_role: this.$store.state.authentication.user.roles[0],
          enterprise_address: this.$store.state.authentication.user.enterprise.address,
          enterprise_phone: this.$store.state.authentication.user.enterprise.phone,
          enterprise_main_family: this.$store.state.authentication.user.enterprise.family
        });
        }
      }
    }
  }
}
</script>
