<template>
    <modal :close-button="false">
        <div slot="activator">
            <a :class="['btn', 'btn-outline--secondary']" href="#">
                {{ title }}
            </a>
        </div>
        <div :class="['container']">
            <section :class="['container-header', 'mb-5']">
                <h1 :class="['container-header--title']">
                    <icon icon="tableau-bord" />
                    <span :class="['uppercase', 'bold']">
                        Sélectionnez une filiale et visualisez son tableau de bord
                    </span>
                </h1>
                <p :class="['container-header--description']">
                    En sélectionnant la filiale souhaitée vous pourrez accéder au tableau de bord lié à cette entité.
                </p>

                <div :class="['w-full']">
                    <div :class="['scroller', 'overflow-y-auto', 'bg-primary-lighter', 'm-12', 'p-8']">
                        <fieldset id="affiliates">
                            <legend :class="['text-lg', 'pb-6']">
                                Les filiales
                            </legend>

                            <div v-for="affiliate in affiliates"
                                :key="affiliate.slug"
                                :class="['py-1']"
                            >
                                <input
                                    :key="affiliate.slug"
                                    :value="affiliate.slug"
                                    :id="affiliate.slug"
                                    v-model="affiliateChosen"
                                    name="affiliates"
                                    type="radio"
                                />
                                <label :for="affiliate.slug" :class="['px-1']">
                                    {{ affiliate.title }}
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </section>

            <a :class="['btn', 'btn-primary', 'm-auto', 'w-fit']" :href="'/dashboard/' + affiliateChosen">
                Valider
            </a>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/commons/Modal'
import Contact from '@/components/customs/contacts/Contact'

export default {
  components: {
    Modal,
    Contact
  },
  props: {
    title: {
      type: String,
    },
    affiliates: {
        type: Array,
        default: () => ([]),
        required: true
    },
  },
  data: () => ({
    affiliateChosen: '',
  }),
}
</script>

<style lang="scss" scoped>
.container {
  @apply my-8;

  &-header {
    @apply flex items-center justify-center flex-col;

    &--title {
      @apply flex items-center;
      @apply uppercase font-bold;
    }

    &--description {
      @apply text-center;
      @apply mt-4;
      max-width: 800px;
    }
  }

  .block /deep/ {
    .card {
      @apply flex-1 h-full;
    }
  }

  .map {
    @screen lg {
      width: 66%;
    }
  }

  .favorites-cards {
    @apply flex flex-col justify-between;

    @screen lg {
      width: 32%;
    }

    .favorites {
      @apply my-8;

      @screen lg {
        height: 70%;
        @apply m-0;
      }
    }

    .favorites-card-list {
      @screen lg {
        height: 29%;
      }
    }
  }
}

fieldset {
    columns: 2;

    legend {
        color: var(--color-primary);
        @apply font-bold;
    }
}

.scroller {
    max-height: 330px;
}
</style>
