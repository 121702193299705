<template>
  <div class="indice-detail wrapper">
    <div class="container">
      <div class="container-header">
        <div class="container-header--title">
          <div
            class="flex-1 cursor-pointer"
            @click="$router.push({ name: 'indices-listing' })">
            <div class="icon-text mx-8">
              <icon
                class="mr-0"
                icon="retour"
                size="lg"></icon>
              <span class="uppercase text-sm hidden lg:block">Retour</span>
            </div>
          </div>
          <div class="flex justify-center flex-1">
            <icon
              icon="indices-eco"
              size="xl"/>
            <h1>{{ indice.name }}</h1>
            <icon
              icon="infos-primary-bluer"
              size="sm"
              class="cursor-pointer"
              @click.native="displayModalInfos = true"
            />
          </div>
          <div class="flex-1"/>
        </div>
        <p class="container-header--description">
          {{ indice.description }}
        </p>
        <div class="text-center">
          <button
            class="btn btn-dark btn-small mx-auto mt-3 sm:hidden"
            @click.stop="onFilterClick"
          >
            Filtrer
          </button>
        </div>
      </div>
    </div>

    <div class="background">
      <div class="container container-body">
        <div class="container-body__chart">
          <div class="filters">
            <div>
              <button
                class="btn btn-small"
                @click.stop="onFilterClick"
              >
                <icon
                  icon="fleche-bleue"
                  size="sm"
                  class="mr-2"
                />
                <span class="font-normal">Filtrer</span>
              </button>
            </div>
            <div class="tag-mesh">
              <tag
                v-for="(tag, index) in dateTags"
                :key="index"
                :label="tag.label"
                variant="secondary"
                :class="{
                  'tag-secondary--active': getActiveState(tag)
                }"
                @click.native="onTagClick(tag.value, 'date')"
              />
            </div>
            <div class="tag-mesh">
              <tag
                v-for="(tag, index) in currencyTags"
                :key="index"
                :label="tag.label"
                variant="secondary"
                :class="['uppercase', { 'tag-secondary--active': tag.value.currency === filters.currency }]"
                @click.native="onTagClick(tag.value, 'currency')"
              />
            </div>
          </div>
          <Card flexed class="card--chart">
            <Chart
              :points="indice.points"
              :currency="filters.currency === 'base' && indice.currencyBase ? indice.currencyBase.symbol : '€'"
              :unity="indice.unityBase ? indice.unityBase.symbol : ''"
              :end-date="indice.valuesUpdateDate"
            />
          </Card>
        </div>

        <div class="container-body__variations">
          <p class="update-date"><span class="text-sm text-primary-bluer">Dernière mise à jour :</span> {{ indice.valuesUpdateDate }}</p>
          <Card flexed class="card--variations">
            <VariationsDetail
              :currency="filters.currency === 'base' && indice.currencyBase ? indice.currencyBase.symbol : '€'"
              :unity="indice.unityBase ? indice.unityBase.symbol : ''"
              :value="indice.value"
              :variation="indice.variation"
              :january-variation="indice.januaryVariation"
              :year-variation="indice.yearVariation"
              :periode-variation="indice.periodeVariation"
            />
          </Card>
        </div>
      </div>
    </div>

    <modal class="relative" :visible.sync="displayModalInfos">
      <ul class="p-6">
        <li v-if="indice.currencyBase && indice.currencyBase.name">
          <strong>Devise de base :</strong> {{ indice.currencyBase.name }}
        </li>
        <li v-if="indice.unityBase && indice.unityBase.name">
          <strong>Unité de base :</strong> {{ indice.unityBase.name }}
        </li>
        <li v-if="indice.originCountry">
          <strong>Pays d'origine :</strong> {{ indice.originCountry }}
        </li>
        <li v-if="indice.deliveryCountry">
          <strong>Pays de livraison :</strong> {{ indice.deliveryCountry }}
        </li>
        <li v-if="indice.valuesStartDate">
          <strong>Date de début des données :</strong> {{ indice.valuesStartDate }}
        </li>
        <li v-if="indice.frequencyBase">
          <strong>Fréquence de mise à jour de base : </strong>{{ indice.frequencyBase }}
        </li>
      </ul>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Card from '@/components/commons/Card.vue'
import Chart from '@/components/amcharts/Indices.vue'
import RenderHtml from '@/components/templates/RenderHtml.vue'
import Tag from '@/components/commons/Tag.vue'
import VariationsDetail from '@/components/customs/indices/VariationsDetail.vue'
import dates from '@/mixins/dates'

export default {
  components: {
    VariationsDetail,
    Tag,
    Card,
    Chart,
    RenderHtml,
  },

  mixins: [dates],

  data() {
    return {
      displayModalInfos: false,
    }
  },

  computed: {
    ...mapState('indices', {
      loading: state => state.loading,
      indice: state => state.detail.entity,
    }),
    ...mapState({
      isDrawerOpen: state => state.isDrawerOpen,
    }),

    dateTags() {
      const endDate = this.parseDateFromFormat(this.indice.valuesUpdateDate, 'dd/mm/yyyy')
      return [
        {
          label: '1 an',
          value: {
            startDate: new Date(new Date().setFullYear(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate())),
            endDate: endDate
          }
        },
        {
          label: '18 mois',
          value: {
            startDate: new Date(new Date().setFullYear(endDate.getFullYear() - 1, endDate.getMonth() - 6, endDate.getDate())),
            endDate: endDate
          }
        },
        {
          label: '2 ans',
          value: {
            startDate: new Date(new Date().setFullYear(endDate.getFullYear() - 2, endDate.getMonth(), endDate.getDate())),
            endDate: endDate
          }
        },
        {
          label: '3 ans',
          value: {
            startDate: new Date(new Date().setFullYear(endDate.getFullYear() - 3, endDate.getMonth(), endDate.getDate())),
            endDate: endDate
          }
        },
        {
          label: 'Tout',
          value: {
            startDate: null,
            endDate: null
          }
        },
      ];
    },
    currencyTags() {
      const tags = []
      if (this.indice.type === 'mintec' && this.indice.currencyBase?.code) {
        tags.push({
          label: this.indice.currencyBase?.code,
          value: {
            currency: 'base'
          }
        })
      }
      tags.push({
        label: 'EUR',
        value: {
          currency: 'euro'
        }
      })
      return tags
    },
    filters() {
      return this.$store.state.indices.detail.filters
    }
  },

  async beforeMount() {
    this.$store.commit('indices/RESET_STATE')
    await this.fetch()
  },

  methods: {
    async fetch() {
      await this.$store.dispatch('indices/getDetail', this.$route.params.id)
    },
    onFilterClick() {
      this.$store.dispatch('drawerStatus', !this.isDrawerOpen)
    },
    async onTagClick(value, type) {
      if (type === 'date') {
        this.$store.commit('indices/SET_DETAIL_FILTERS', {
          ...this.filters,
          ...value,
        })
      } else if (type === 'currency') {
        const endDate = this.parseDateFromFormat(this.indice.valuesUpdateDate, 'dd/mm/yyyy')
        this.$store.commit('indices/SET_DETAIL_FILTERS', {
          startDate: new Date(new Date().setFullYear(endDate.getFullYear() - 2, endDate.getMonth(), endDate.getDate())),
          endDate,
          ...value,
        })
      }
      await this.fetch()
    },
    getActiveState(tag) {
      return tag.value.startDate?.setHours(0,0,0,0) === this.filters.startDate?.setHours(0,0,0,0) &&
        tag.value.endDate?.setHours(0,0,0,0) === this.filters.endDate?.setHours(0,0,0,0)
    }
  }
}
</script>
