import axios from '@/axios'

// Mocks :
// import articles from '../../../../mocks/articles/list.json'
// import formations from '../../../../mocks/articles/formations.json'
// import article from '../../../../mocks/articles/example.json'
// import categories from '../../../../mocks/articles/categories.json'
// import filters from '../../../../mocks/articles/filters.json'
// import formation from './../../../../mocks/articles/formation.json'

/**
 * On récupère les différents filtres possibles des articles
 */
const requestArticlesFilters = async ({ commit }) => {
  try {
    const filters = await axios.get(`${process.env.VUE_APP_API_VERSION}/articles/filters`)

    // commit('SET_ARTICLES_FILTERS', filters)
    commit('SET_ARTICLES_CATEGORIES', filters.data.category.items)
  } catch (err) {
    console.error(err)
  } finally {
    // Effet quoiqu'il arrive
  }
}

/**
 * On requête la liste des articles à afficher selon des paramètres
 * @param {Number} payload.id L'Id du type de catégorie
 * @param {Number} payload.page La page des articles affichés
 */
const requestArticlesByParams = async ({ commit }, payload) => {
  commit('SET_ARTICLES_PROCESSING', true)

  try {
    const articles = await axios.get(`${process.env.VUE_APP_API_VERSION}/articles`, {
      params: {
        category: payload.id,
        page: payload.page
      }
    })

    commit('SET_ARTICLES_LIST', articles.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_ARTICLES_PROCESSING', false)
  }
}

const requestResultsArticles = async ({ commit }, search) => {
  commit('SET_ARTICLES_PROCESSING', true)

  try {
    const articles = await axios.get(`${process.env.VUE_APP_API_VERSION}/search/results/articles`, {
      params: {
        s: search
      }
    })

    commit('SET_ARTICLES_LIST', articles.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_ARTICLES_PROCESSING', false)
  }
}

/**
 * On requête l'article à afficher selon le slug de la route
 * @param {String} slug le fournisseur
 */
const requestArticleBySlug = async ({ commit, dispatch }, slug) => {
  commit('SET_ARTICLE_PROCESSING', true)
  try {
    const article = await axios.get(`${process.env.VUE_APP_API_VERSION}/article/${slug}`)

    commit('SET_CURRENT_ARTICLE', article.data)

    // Get last articles
    const payload = {
      ignoredId: article.data.id
    }
    dispatch('requestLastArticles', payload)

  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_ARTICLE_PROCESSING', false)
  }
}

/**
 * Requête de la liste des formations
 * @param {*} param0
 */
const requestFormations = async ({ commit }, payload) => {
  commit('SET_ARTICLE_PROCESSING', true)

  try {
    const formations = await axios.get(`${process.env.VUE_APP_API_VERSION}/formations`, {
      params: {
        category: payload.category,
        page: payload.page
      }
    })
    commit('SET_FORMATIONS_LIST', formations.data)

    const filters = await axios.get(`${process.env.VUE_APP_API_VERSION}/formations/filters`)
    commit('SET_FORMATIONS_CATEGORIES', filters.data.formation_category)
    // console.log(filters)

  } catch (err) {
    console.error(err.response.data)
  } finally {
    commit('SET_ARTICLE_PROCESSING', false)
  }
}

/**
 * On recherche une formation en fonction de son slug fourni via l'URL
 * @param {String} slug de la formation
 */
const requestFormationBySlug = async ({ commit }, slug) => {
  commit('SET_ARTICLE_PROCESSING', true)
  try {
    const formation = await axios.get(`${process.env.VUE_APP_API_VERSION}/formation/${slug}`)
    commit('SET_CURRENT_FORMATION', formation.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_ARTICLE_PROCESSING', false)
  }
}

const requestLastArticles = async ({ commit }, payload) => {
  commit('SET_ARTICLE_PROCESSING', true)
  try {
    const articles = await axios.get(`${process.env.VUE_APP_API_VERSION}/last-articles`, {
      params: payload
    })
    commit('SET_LAST_LIST', articles.data)
  } catch (err) {
    console.error(err)
  } finally {
    commit('SET_ARTICLE_PROCESSING', false)
  }
}

export default {
  requestArticlesFilters,
  requestArticlesByParams,
  requestResultsArticles,
  requestArticleBySlug,
  requestFormations,
  requestFormationBySlug,
  requestLastArticles
}
