<template>
  <div class="wrapper">
    <div class="subcontainer subcontainer-large">

      <div
        class="subcontainer-header">
        <h1 class="flex items-center font-bold uppercase text-xl mb-4">
          <icon
            icon="feuille-route"
            size="lg"/>
          <span>{{ pageTitle }}</span>
        </h1>

        <p>
          Vous retrouverez sur cette page l'ensemble des études et sujets traités par Mercurial dans le cadre de votre adhésion au groupement.
        </p>
      </div>
    </div>

    <div class="background">
      <div class="subcontainer subcontainer-large subcontainer--free">
        <div class="subcontainer-content">
          <list-interactions />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ListInteractions from '@/components/customs/roadmap/ListInteractions.vue'
import Pagination from '@/components/commons/Pagination'
import axios from '@/axios'
import MapView from '@/components/mapbox/MapView'

export default {
  components: {
    MapView,
    ListInteractions,
    Pagination
  },

  data: function () {
    return {
      excelContent: [],
      map: false,
    }
  },

  computed: {
    pageTitle () {
      return 'Études d\'achat'
    },
  },

  methods: {
    /**
     * Ouverture de l'onglet de navigation en mobile
     */
    handleOpenDrawer () {
      this.$store.dispatch('drawerStatus', true)
    },
  },

}
</script>

<style lang="scss" scoped>
.subcontainer-header {
  @apply justify-between;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    color: #333333;
    font-weight: 100;
  }
}
</style>
